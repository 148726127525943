import MotionHoc from "./MotionHoc";
import {useState,useEffect, useContext, useRef} from 'react'
import styled from "styled-components";
import { Play, CloseSquare,Delete,Download,Document } from 'react-iconly'
import { map } from 'lodash'
import {Context} from '../context/Context'
import { Card, Grid, Col,Avatar, Text,Row,Button, Divider,Input, Spacer, Loading, Progress,Container, Modal,Tooltip } from '@nextui-org/react';
import axios from "axios";
import ScrapeResults from '../components/scrapeResults/ScrapeResults';
import {  toast } from 'react-toastify';
import download from 'downloadjs';

const Form = styled.form`
  input {
    padding: 10px !important;
  }
  display:flex ;
  flex-direction:column ;
`

const Select = styled.select`
  width: 100%;
  /* width: 200px; */
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  margin-left: 10px;
  border-radius: 5px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const HomeComponent = () => {

  const { user } = useContext(Context);

  const selenoid = "https://hitbridge-scraper-node.awsh.live/#/"


  const [isScraping, setIsScraping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [visible, setVisible] = useState(false);
  // const handler = () => setVisible(true);
  // const closeHandler = () => {
  //     setVisible(false);
  //     console.log('closed');
  // };
  // const endpoints = ['test 1', 'test 2']




  const [reloadEndpoints, setReloadEndpoints] = useState(false);
  const [endpoints, setEndpoints] = useState(null)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const getEndpoints = async () => {
      setLoading(true)
      try {
          const {data} = await axios.get('/api/endpoints',{   
            headers: {
            'Authorization': `Bearer ${user.token}` 
          }})

          setEndpoints(data.endpoints)
      
          setLoading(false)
          setReloadEndpoints(false)
      } catch (error) {
        setLoading(false)
          //console.log(error)
          // setSessions({...values})
      }
  };

  getEndpoints();

  }, [reloadEndpoints]);
  const [isAlive, setisAlive] = useState(true);
  useEffect(() => {
    const checkIfAlive = async () => {
      // setisAlive(true)
      try {
          const {data} = await axios.get('/api/check-if-alive',{   
            headers: {
            'Authorization': `Bearer ${user.token}` 
          }})
          console.log(data)
      
          setisAlive(true)
      } catch (error) {
        setisAlive(false)
          //console.log(error)
          // setSessions({...values})
      }
  };

  checkIfAlive();
  const interval = setInterval(async() => { await checkIfAlive()}, 10000)
  return () => {
    clearInterval(interval);
  };

  }, [reloadEndpoints]);



  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => {
      setVisible(false);
 
  };


  const [values, setValues] = useState({
    formData: '',
    // endpointValue: '',
    file: '',
});


const { formData} = values;
// const { formData, endpointValue } = values;

  useEffect(() => {
    setValues({ ...values, formData: new FormData() })
  }, [])

  const handleChange = name => e => {
    const value = (name === 'file') ? e.target.files[0] : e.target.value;
    formData.set(name, value)
    setValues({ ...values, [name]: value });
  };


  const [isProcessing, setIsProcessing] = useState(false);
const sendFormData = (token, formData) => {



  return fetch(`/api/process-csv`, {
  // return fetch(`/api/scrape/data`, {
      method: 'POST',

      headers: {
          //'Content-type': 'multipart/form-data;',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          authorization: `Bearer ${token}`
      },
      body: formData
  })
      .then(response => {
          return response.json();
      })
      .catch(err => {
 
      });

}


var [scrapeLogs, setScrapeLogs] = useState('');
const [isFetching, setisFetching] = useState(false);
const scrapeHeart = async () => {
  setisFetching(true)
  const {data} = await axios.get(`/api/result-logs`)
  if(JSON.stringify(data, null, 2) === '{}'){
    setScrapeLogs('No Results')
    setisFetching(false)
  } else {
    setisFetching(false)
    setScrapeLogs(data)
  }
}

const [cleanLogsLoader, setcleanLogsLoader] = useState(false);
const cleanLogs = async () => {
  setcleanLogsLoader(true)
  const {data} = await axios.get(`/api/delete-scrape-logs`)
  if(data){
    setcleanLogsLoader(false)

  }
}

const [stopScrapeTimer, setstopScrapeTimer] = useState(false);
const stopScrape = async () => {
  setstopScrapeTimer(true)
  const {data} = await axios.get(`/api/stop`)

  setTimeout(function(){
    setstopScrapeTimer(false) 

  }, 10000);
}
const [downloadTimer, setDownloadTimer] = useState(false);
const downloadResults = async () => {
  setDownloadTimer(true)
  try {
    const {data} = await axios.get(`/api/download-report`)

    console.log(data)
      let url = `https://hb-scraper-prod.awsh.live${data}`
      let a = document.createElement('a');
      a.href = url;
      a.download = 'results.csv';
      a.click();
  
    setDownloadTimer(false)
  } catch (error) {
    alert('Server is busy ')
    setDownloadTimer(false)
}



  // axios.get("/api/download-report")
  // .then(resp => {
  //        download(resp.data, 'hola.csv');
  // });
  // const res=await axios.get(`/api/download-report`,{
  // responseType: 'blob' })
  // download(res.data);
  // fetch('/api/download-report' ,{timeout: 6000})

  // fetch('/api/download-report' ,{timeout: 6000})
  // .then(response => {
  //   if(response.status === 504 ) {
  //     setDownloadTimer(false) 
  //     return alert('Server is busy, try again later')
  //   }
  //   response.blob().then(blob => {

  //     let url = window.URL.createObjectURL(blob);
  //     let a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'results.csv';
  //     a.click();
  //     setDownloadTimer(false) 
  //   }).catch(e => {
  //     console.log('THERE WAS AN ERR')
  //     setDownloadTimer(false) 
      
  //   })

// });

  // setTimeout(function(){
  //   setDownloadTimer(false) 

  // }, 10000);
}
const downloadDocumentation = async () => {
  try {
    const {data} = await axios.get(`/api/download-doc`)

    console.log(data)
      let url = `https://hb-scraper-prod.awsh.live${data}`
      let a = document.createElement('a');
      a.href = url;
      a.download = 'scrape-docs.pdf';
      a.click();
  } catch (error) {
    alert('Server is busy, try again later ')
  }
}

const [cleanQueueTimer, setCleanQueueTimer] = useState(false);
const cleanQueue = async () => {
  setCleanQueueTimer(true)
  const {data} = await axios.get(`/api/drop-queue`)
  await getQueueAmount()

  setTimeout(function(){
    setCleanQueueTimer(false) 
    toast.dark(`✔️ Queue Cleaned`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

  }, 5000);
}
const [cleanCollectionLoader, setCleanCollectionLoader] = useState(false);
const cleanCollections = async () => {
  setCleanCollectionLoader(true)
  const {data} = await axios.get(`/api/drop-scrape-collections`)

  if(data) {
    setCleanCollectionLoader(false)
  }
}
const [checkFile, setCheckFile] = useState('');
const [totalDomains, setTotalDomains] = useState('');
const checkFileExists = async () => {
  const {data} = await axios.get(`/api/check-file`)

  setCheckFile(data.message)
  setTotalDomains(data.totalDomains)

}


const [queueAmount, setqueueAmount] = useState([]);
// const [queueAmount, setqueueAmount] = useState(null);
const [loadQueue, setLoadQueue] = useState(false);
const [ableToRun, setAbleToRun] = useState(true);
const [poll,setPoll] = useState(0)
const [totalPoll,setTotalPoll] = useState(0)
const [amProcessing,setAmProcessing] = useState(false)
const getQueueAmount = async () => {
  setLoadQueue(true)
  const {data} = await axios.get(`/api/get-current-queue`,{   
    headers: {
    'Authorization': `Bearer ${user.token}` 
  }})
  if(data){
    if(data.queueItems.length > 0){
      setAbleToRun(false)
    }
    if(data.queueItems.length === 0){
      setAbleToRun(true)
    }
    setLoadQueue(false)
    setqueueAmount(data.queueItems)
    setPoll(data.poll)
    setTotalPoll(data.totalPoll)
    setAmProcessing(data.amProcessing)
  }
}
// const [downAmount, setDownamount] = useState(false);
// const [loadDownAmount, setLoadDownamount] = useState(false);
// const getQueueAmount = async () => {
//   setLoadQueue(true)
//   const {data} = await axios.get(`/api/get-current-queue`)
//   if(data){
//     console.log(data)
//     setLoadQueue(false)
//     setqueueAmount(data.queueItems)
//   }
// }



// useEffect(async() => {
//   await checkFileExists();
// }, [])

useEffect(() => {
  const interval = setInterval(async() => { await scrapeHeart()}, 15000)
  return () => {
    clearInterval(interval);
  };
}, [])
useEffect(async() => {
  await getQueueAmount()
  const interval = setInterval(async() => { await getQueueAmount()}, 15000)
  return () => {
    clearInterval(interval);
  };
}, [])
useEffect(async() => {
  await checkFileExists();
  const interval = setInterval(async() => { await checkFileExists()}, 30000)
  return () => {
    clearInterval(interval);
  };
}, [])


const ref = useRef();

const reset = () => {
  ref.current.value = "";
};

const handleSubmit = async e => {
  e.preventDefault();
  setIsProcessing(true)
  // if(!formData.has('file') && !formData.has('endpointValue')) {
  //   alert('Please check fill the inputs')
  //   setIsScraping(false)
  // }

  try {
    console.log(formData)
    if(checkFile === 'There is no CSV file uploaded') {
      if(!!formData.entries().next().value === false) {
        toast.dark(`❌${'There is no CSV file in the server, please upload one.'}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          setIsProcessing(false)
          return
      }
    }
    await sendFormData(user.token, formData).then((data) => {
      console.log(data)

      if(data.error){
        toast.dark(`❌${data.error}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
          for (var key of formData.keys()) {
            // here you can add filtering conditions
            formData.delete(key)
            };
            ref.current.value = "";
      
          setIsProcessing(false)
      }
      if(data.message){
        toast.dark(`⌛${data.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
          
          setIsProcessing(false)
      }

  })
  } catch (error) {
    alert('Something went wrong')
    setIsProcessing(false)
  }
};



// const [endpointValue, setEndpointValues] = useState(endpoints[0].endpointValue)
// const [endpointValue, setEndpointValues] = useState(endpoints ? endpoints[0].endpointValue : 'Please add an endpoint first')
// const handleChangeForm =  e => {
//   setEndpointValues(e.target.value)
// };


const handleSubmitScrape = async (e) => {
e.preventDefault();
try {
  setIsScraping(true)

    const {data} = await axios.post("/api/scrape/data", 
      {
        endpointValue: endpoints[0].endpointValue,
      },
      {
        headers: {
          //'Content-type': 'multipart/form-data;',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
          authorization: `Bearer ${user.token}`
      }
      }
    );
    setVisible(false);
        // closeHandler()
    toast.dark(`✔️ Scrape is running`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });


    setTimeout(function(){
      setIsScraping(false)

    }, 5000);
    console.log(data)


} catch (error) {
  console.log(error)
  setIsScraping(false)
  alert('There was an error, check if endpoint was selected')
}
};



//   console.log(userData)
//   for (var value of userData.values()) {
//     console.log(value);
//  }
//   setIsScraping(true)
//   try {
//     const {data} = await axios.post("/api/scrape/data", userData, 
//     {   
//       headers: {
//       'Authorization': `Bearer ${user.token}` 
//     }});


//      setIsScraping(false);

//  } catch (error) {
//      console.log(error);
//      setIsScraping(false);
//  }
  // update(token, userData).then(data => {
  //     if (data.error) {
  //         setValues({ ...values, error: data.error, success: false, loading: false });
  //     } else {
  //         updateUser(data, () => {
  //             setValues({
  //                 ...values,
  //                 username: data.username,
  //                 name: data.name,
  //                 email: data.email,
  //                 about: data.about,
  //                 password: '',
  //                 success: true,
  //                 loading: false
  //             });
  //         });
  //     }
  // });
  const RunButton = () => {

  //   const [endpointValue, setEndpointValues] = useState('')
  //   const handleChangeForm =  e => {
  //     setEndpointValues(e.target.value)
  // };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setIsScraping(true)
 
  //       const {data} = await axios.post("/api/scrape/data", 
  //         {
  //           endpointValue,
  //         },
  //         {
  //           headers: {
  //             //'Content-type': 'multipart/form-data;',
  //             'Access-Control-Allow-Origin': '*',
  //             Accept: 'application/json',
  //             authorization: `Bearer ${user.token}`
  //         }
  //         }
  //       );
  //       toast.dark(`✔️ Scrape is running`, {
  //         position: "top-center",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         });
    
 
  //       setTimeout(function(){
  //         setIsScraping(false)

  //       }, 5000);
  //       console.log(data)
  

  //   } catch (error) {
  //     console.log(error)
  //     setIsScraping(false)
  //     alert('There was an error, check if endpoint was selected')
  //   }
  // };
    return (
      <>
        {ableToRun ? (
          <>
            <Card color={'warning'}>
              <Text
                css={{ fontWeight: "$bold", color: "$white" }}
                transform="capitalize"
              >
                Attention!
              </Text>
              <Text css={{ fontWeight: "$bold", color: "$white" }} span>
                Please add first some domains to the queue to be able to start scraping
              </Text>
            </Card>
          </>
        ) : (
          <>
          
          <Button size="md">Start Scrape</Button>
          </>
        )}

      </>
    )
  }


  return (
    <>
    <div style={{position:'absolute', margin: 10, top: 0,right:0}}>
      <Row justify="center">
            {stopScrapeTimer ? <Loading/> : (
              <Tooltip content={"Restarts the application server (back to the start in any given time)" }  placement="bottom">
              <Button auto flat color="warning" onClick={ () => stopScrape() } >
              <Play set="bold" primaryColor="yellow"/>    Restart Server
              </Button>
              </Tooltip>

            )}
            <Spacer x={1}/>
            {
              cleanCollectionLoader ? <Loading/> : (

                <Tooltip content={"Download the docs for Awsh Bot Scraper and learn how to use it" }  placement="bottom">
                <Button auto flat  color="primary" onClick={ () => downloadDocumentation() } >
                <Document set="bold" primaryColor="blue"/>      Documentation 
                </Button>
              </Tooltip>

              )
            }
            <Spacer x={1}/>
            {
              cleanLogsLoader ? <Loading/> : (
                <Tooltip content={"This will clean the logs of your previous scraped results in the Scrape Results pane" }  placement="bottom">
                <Button auto flat  color="error" onClick={ () => cleanLogs() } >
                <CloseSquare set="bold" primaryColor="red"/>      Clean Logs 
                 </Button>
                </Tooltip>
              )
            }
            <Spacer x={1}/>
            {
              cleanCollectionLoader ? <Loading/> : (
                <Tooltip content={"This will erase all your scraped data and empty the database records" }  placement="bottom">
                <Button auto flat  color="error" onClick={ () => cleanCollections() } >
                <Delete set="bold" primaryColor="red"/>      Clean Collections 
                </Button>
                </Tooltip>
              )
            }
            <Spacer x={1}/>
            {isAlive ? (
              <Loading type="points-opacity" color="success"> Server is Running </Loading>
            ) : (
              // <Progress indeterminated value={50} color="secondary" status="secondary" />
              <Loading type="points-opacity" color="error" textColor="error"> Server is Down </Loading>
            )}

      </Row>

 
    </div>
      <Grid.Container gap={2}> 
        <Grid >  
        <Tooltip content={checkFile} placement="bottom" color="primary" >
          <Card bordered shadow={false} hoverable css={{ w: "400px" }}>
              <Card.Header>
                            {/* There are {queueAmount.length} sites in queue <br /> */}
              <Row justify="center">
              <Text  b>
              Sites in queue 
              </Text>
              <Tooltip content={"Broken sites are not filled in this count"} rounded color="primary">
              <Avatar 
                squared 
                text={`${queueAmount.length}`} />
              </Tooltip>

              </Row>
              <Spacer x={1}/>
              of
              <Row justify="center">
              <span>{`${totalDomains}`}</span>
              </Row>
              <Tooltip placement="top" color="error" content={'Clean queue list'}> 
                {
                  cleanQueueTimer ? <Loading/> : (
                    <>
                    <Button size="sm" auto flat  color="error" onClick={ () => cleanQueue() } >
                    <Delete set="bold" primaryColor="red"/>      Delete Queue 
                    </Button>
                    </>
                  )
                }
              </Tooltip>
                {/* <Text  b>Submit CSV File</Text> */}
              </Card.Header>
              <Divider/>
              <Form onSubmit={handleSubmit}>
                <Card.Body css={{ py: '$10' }}>
                  <Text >
                    Attach the file with the domain list.
                  </Text>
                  <Spacer y={1}/>
                  <Input
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    size="lg"
                    type="file"
                    name="file" onChange={handleChange('file')}
                  />
                  {/* <Spacer y={1}/>
                  <Text >
                    Select an endpoint.
                  </Text>
                  <Spacer y={1}/>
                <Select  value={endpointValue} name="endpointValue" onChange={handleChange('endpointValue')}>
                  <option value="" hidden>
                    Select Endpoint
                  </option>
                  {map(endpoints, (endpoints) => (
                                    <option value={endpoints._id} >{endpoints.endpointValue} </option>
                                ))}
                </Select> */}
        
                </Card.Body>
                <Divider/>
                <Card.Footer>
                  <Row justify="center" fluid>
                    {isProcessing ? (
                        <Col justify="center">
                        <Text >
                          Csv Processing is running  <Loading type="points-opacity"/>
                        </Text>
                        <Progress indeterminated shadow value={50} color="primary" status="primary" />
                        </Col>
                    ) : (
            
                        <Button size="sm" >Run CSV/Domain processing</Button>
             
 
                    )}
                  </Row>
                </Card.Footer>
                {
                  amProcessing ? (
                    <>
                    <Card.Footer>
                      Processing is in progress: {poll} of {totalPoll} done
                    </Card.Footer>
                    <Card.Footer>
                    <Progress color="primary" shadow striped value={poll} max={totalPoll}/>
                    </Card.Footer>
                    </>
                  ) : 
                  ( '' )
                }

              </Form>
          </Card>
          </Tooltip>
          <Spacer y={1}/>
          <Row justify="center">

          </Row>
          <Spacer y={1}/>
          <Row justify="center">

          </Row>
          <Spacer y={1}/>
          <Row justify="center" >
            <Spacer x={1}/>
            <Button color="success" auto flat onClick={handler}>
            <Play set="bold" primaryColor="green"/>    Run Scrape
        </Button>
        <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
        >
          <Modal.Header>
          {ableToRun ? (
            <>
            </>
          ) : (
            <Text id="modal-title" size={18}>
            There are {queueAmount.length} sites in queue ready to be scraped
          </Text>
          )}

              {/* <Text id="modal-title" size={18}>
              <Tooltip placement="top" color="primary" content={'Clean queue list'}> 
                  {
                    cleanQueueTimer ? <Loading/> : (
                      <>
                      <Button auto flat  color="error" onClick={ () => cleanQueue() } >
                        <Delete set="bold" primaryColor="red"/>      Delete Queue 
                      </Button>
                      </>
                    )
                  }
                </Tooltip>
              </Text> */}
          </Modal.Header>
          <Modal.Body>
          <Form onSubmit={handleSubmitScrape}>
                  <Spacer y={1}/>
                {/* <Select  value={endpointValue} onChange={handleChangeForm} ref={ref}>
                  <option value="" hidden>
                    Select Endpoint
                  </option>
                  <option value={'test'} >'test' </option>
                  {map(endpoints, (endpoints) => (
                                    <option value={endpoints.endpointValue} >{endpoints.endpointValue} </option>
                                ))}
                </Select> */}

                {/* <Spacer y={1}/> */}
                {/* <Divider/> */}

            <Row justify="center">

            {isScraping ? ( <Loading type="points-opacity"/>) : (
              <>
                <RunButton/>
                {/* <Tooltip placement="top" color={ableToRun ? 'warning' : 'primary'} content={ableToRun ?  'There are no sites in queue' : 'Start Scrape' }>
                  <Button size="sm"  disabled={ableToRun}>Run</Button>
                </Tooltip> */}
              </>
            )}              
            </Row>
            <Spacer y={1}/>
          </Form>

          </Modal.Body>
          <Modal.Footer>
          {/* <Button flat auto color="error" onClick={() => setVisible(false)}> */}
          <Button flat auto color="error"  onClick={closeHandler}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
            {/* <ScrapeCardForm/> */}
              {/* <Tooltip trigger="click" content={<ScrapeCardForm/>}> */}
                {/* <Button color="success" auto flat onClick={handler}>
                <Play set="bold" primaryColor="green"/>    Run Scrape
                </Button> */}
              {/* </Tooltip> */}
          </Row>
          


        </Grid>  
        <Grid > 
        
          {isLoading ? <Loading size="xl"/> : (
            // <Card bordered shadow={false} hoverable >
          <Card bordered shadow={false} hoverable css={{ w: "750px", h: "500px" }}> 
              <Card.Header>
                
                <Row justify="space-between" style={{alignItems: "end"}}>
                <Text  b>Scrape Results</Text>
                {
                  downloadTimer ? <Loading size="sm"/> : (
                    <>
                      <Text b  >Download scrape-reports <Download set="bold" primaryColor="green" onClick={ () => downloadResults() }/> </Text>
                    </>
                  ) 
                }

                </Row>

              </Card.Header>
              <Divider/>
                <Card.Body css={{ py: '$10' }}>
                  {isFetching ?  <Loading type="points-opacity" size="xl"/> : <pre>{JSON.stringify(scrapeLogs, null, 2) }</pre>  }
                
                  {/* {JSON.stringify(scrapeLogs)} */}
                  {/* {JSON.parse(scrapeLogs)} */}
                  {/* <ScrapeResults scrapeResults={dummy}/> */}
                </Card.Body>
            </Card>
          )}
    

        </Grid>  
      </Grid.Container>

    </>
  )
};

const Home = MotionHoc(HomeComponent);

export default Home;
