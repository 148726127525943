import { Route, Switch, useLocation,Redirect } from "react-router";
import { useContext } from "react";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Sidebar from "./Sidebar";
import UsersPanel from "./Pages/UsersPanel";
import Me from "./Pages/me/Me";
import User from "./Pages/user/User";
import Endpoint from "./Pages/endpoint/Endpoint";
import Calender from "./Pages/Calender";
import Documents from "./Pages/Documents";
import Endpoints from "./Pages/Endpoints";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import { Context,ContextProvider } from "./context/Context";
import 'react-toastify/dist/ReactToastify.css';

const Pages = styled.div`
  width: 100vw;
  height: 100vh;
  z-index:99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);

  h1 {
    font-size: calc(2rem + 2vw);
    background: linear-gradient(to right, #803bec 30%, #1b1b1b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const BgOne = styled.img`
    /* display: none; */
    /* opacity: 0; */
    position: fixed;
    z-index: -1;
    bottom: -50%;
    left: -10%;
    right: -50%;
`
const BgTwo = styled.img`
    /* display: none; */
    /* opacity: 0; */
    position: fixed;
    z-index: -1;
    bottom: -50%;
    left: -10%;
    right: -50%;
`

function App() {
  const location = useLocation();
  const { user } = useContext(Context);
  // const user  = true
  return (
    <>
      <ToastContainer />
      {user ? <Sidebar/>: ''}
      {/* {user ? <Sidebar/>: <Login/>} */}
      <Pages>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
     
            <Route exact path="/" >
            {/* {user ? <Dashboard/> : <Login/>} */}
            {user ? <Home/> : <Login/>}
            </Route>
            <Route exact path="/users-panel" >
            {/* {user ? <Dashboard/> : <Login/>} */}
            {user ? <UsersPanel/> : <Login/>}
            </Route>
            <Route exact path="/endpoints" >
            {/* {user ? <Dashboard/> : <Login/>} */}
            {user ? <Endpoints/> : <Login/>}
            </Route>

            <Route exact path="/me">
              {user ? <Me/> : <Redirect to="/login"/>}
              </Route>

              <Route exact path="/user/:userId">
              {user ? <User/> : <Redirect to="/login"/>}
              </Route>
              <Route exact path="/endpoint/:endpointId">
              {user ? <Endpoint/> : <Redirect to="/login"/>}
              </Route>
            {/* <Route path="/team" component={Team} />
            <Route path="/calender" component={Calender} />
            <Route path="/documents" component={Documents} />
            <Route path="/projects" component={Projects} />
     */}

          </Switch>
        </AnimatePresence>
      </Pages>
      <BgOne src="https://nextui.org/gradient-left-dark.svg"/>
      <BgTwo src="https://nextui.org/gradient-right-dark.svg"/>
    </>
  );
}

export default App;
