import React, { useState, useContext } from "react";
//All the svg files
import logo from "../assets/logo.svg";
import Home from "../assets/home-solid.svg";
import Team from "../assets/social.svg";
import Settings from "../assets/settings.svg";
import Calender from "../assets/sceduled.svg";
import Projects from "../assets/starred.svg";
import Documents from "../assets/draft.svg";
import PowerOff from "../assets/power-off-solid.svg";
import styled from "styled-components";
import { NavLink,Link } from "react-router-dom";
import {Context} from '../context/Context';


const Container = styled.div`
  position: fixed;
  z-index: 99;

  .active {
    border-right: 4px solid var(--white);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

const Button = styled.button`
  background-color: var(--black);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.3s ease;
  }

  &::before {
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }

  &::after {
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  /* background-color: var(--black); */
  background-color: transparent;
  width: 3.5rem;
  height: 80vh;
  margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  padding: 1rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: relative;
`;

const Logo = styled.div`
  width: 2rem;

  img {
    width: 100%;
    height: auto;
  }
`;

const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);

  padding: 2rem 0;

  position: absolute;
  top: 6rem;
  left: 0;

  width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.5s ease;
  border-radius: 0 30px 30px 0;
`;

const Item = styled(NavLink)`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;

  display: flex;
  padding-left: 1rem;

  &:hover {
    border-right: 4px solid var(--white);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }

  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;
const ItemTo = styled.a`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;

  display: flex;
  padding-left: 1rem;

  &:hover {
    border-right: 4px solid var(--white);

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }

  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "100%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.3s ease;
`;

const Profile = styled.div`
  width: ${(props) => (props.clicked ? "14rem" : "3rem")};
  height: 3rem;

  padding: 0.5rem 1rem;
  /* border: 2px solid var(--white); */
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.clicked ? "9rem" : "0")};

  background-color: var(--black);
  color: var(--white);

  transition: all 0.3s ease;

  img {
    /* width: 2.5rem;
    height: 2.5rem; */
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    cursor: pointer;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
    

    &:hover {
      border: 2px solid var(--grey);
      /* padding: 2px; */
    }
  }
`;

const Details = styled.div`
  display: ${(props) => (props.clicked ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.div`
  padding: 0 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h4 {
    display: inline-block;
  }

  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: var(--grey);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Logout = styled.button`
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: transparent;

  img {
    width: 100%;
    height: auto;
    filter: invert(15%) sepia(70%) saturate(6573%) hue-rotate(2deg)
      brightness(100%) contrast(126%);
    transition: all 0.3s ease;
    &:hover {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;

const Sidebar = () => {

  const { user,dispatch } = useContext(Context);


  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [profileClick, setprofileClick] = useState(false);
  const handleProfileClick = () => setprofileClick(!profileClick);

  const handleLogout = () => {
    dispatch({type: "LOGOUT"})
  }

  return (
    <Container>
      <Button clicked={click} onClick={() => handleClick()}>
        Click
      </Button>
      <SidebarContainer>
        <Logo>
          <img src={'https://res.cloudinary.com/erracode/image/upload/v1616454090/logo_q386lr.png'} alt="logo" />
          {/* <img src={logo} alt="logo" /> */}
        </Logo>
        <SlickBar clicked={click}>
          <Item
            onClick={() => setClick(false)}
            exact
            activeClassName="active"
            to="/"
          >
            <img src={Home} alt="Home" />
            <Text clicked={click}>Home</Text>
          </Item>
          {/* <Item
            onClick={() => setClick(false)}
            activeClassName="active"
            to="/team"
          >
            <img src={Team} alt="Team" />
            <Text clicked={click}>Team</Text>
          </Item> */}

              <Item exact activeClassName="active" to="/users-panel" onClick={() => setClick(false)}>
                <img src={Team} alt="" />
                <Text clicked={click}>Users</Text>
              </Item>

           {/* {
           user.user.role === 'user' ? '' : (
              <>
              <Item exact activeClassName="active" to="/users-panel" onClick={() => setClick(false)}>
                <img src={Team} alt="" />
                <Text clicked={click}>Users</Text>
              </Item>
              </>
            )
            } */}
          {
           user.user.role === 'user' ? '' : (
              <>
                <Item
                  onClick={() => setClick(false)}
                  activeClassName="active"
                  to="/endpoints"
                >
                  <img src={Documents} alt="Documents" />
                  <Text clicked={click}>Endpoints</Text>
                </Item>
              </>
            )
            }

          <ItemTo
            onClick={() => setClick(false)}
            activeClassName="active"
            href="https://hb-scraper-prod-reporting.awsh.live" target="_blank"
          >
            <img src={Calender} alt="Calender" />
            <Text clicked={click}>Reporting</Text>
          </ItemTo>
          <ItemTo
            onClick={() => setClick(false)}
            activeClassName="active"
            href="http://192.81.131.230:8085/" target="_blank"
          >
            <img src={Projects} alt="Projects" />
            <Text clicked={click}>Selenoid UI</Text>
          </ItemTo>
        </SlickBar>

        <Profile clicked={profileClick}>

          <img
            onClick={() => handleProfileClick()}
            src={Settings}
            // src="https://picsum.photos/200"
            alt="Profile"
          />
          <Details clicked={profileClick}>
            <Name>
            <Link to={"/me"}>
                         <h4>{user ? user.user.username : 'Not logged in'}</h4>
                </Link>
  
            </Name>

            <Logout onClick={handleLogout}>
              <img src={PowerOff} alt="logout" />
            </Logout>
          </Details>
        </Profile>
      </SidebarContainer>
    </Container>
  );
};

export default Sidebar;
