// import MotionHoc from "./MotionHoc";

// const TeamComponent = () => {
//   return <h1>Team</h1>;
// };

// const UsersPanel = MotionHoc(TeamComponent);

// export default UsersPanel;


import React, {useState,useEffect, useContext} from 'react'
import { Modal, Text, Button,Row,Input,Loading } from '@nextui-org/react';

import MotionHoc from "./MotionHoc";
import { Link, Redirect ,useHistory} from "react-router-dom";
import EndpointList from '../components/endpointList/EndpointList';
import styled from 'styled-components';
// import { Modal } from '../components/ModalCreate/Modal';
// import CreateUserForm from '../components/submitUserForm/SubmitUserForm';
import {Context} from '../context/Context'
import axios from "axios";
import {  toast } from 'react-toastify';

const Container = styled.div`
display: flex;
height:97vh;
min-width:800px;
/* backdrop-filter: blur(35px);
  background-color: rgba(255, 255, 255, 0.8); */
  /* background-color: #09090c; */
  // backdrop-filter: blur(3px);
    // background-color: #ffffff13;
    background-color: white;

  /* background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%); */
  /* background: linear-gradient(to bottom right, white 0%, #e6e4ff 70%); */
  border-radius: 2em;
  margin: 1rem 2rem 1rem 6rem;
  // box-shadow: 0 1px 3px rgba(0,0,0,0.9);
  `
  const Wrapper = styled.div`
  align-items: center;
    // width: 90%;
    width: 100%;
    // background: linear-gradient(to bottom right, white  right, #e6e4ff 70%);
    display: flex;
    flex-direction: column;
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
    /* margin: 1rem 8rem 1rem 4rem; */
    margin: 1rem 4rem 1rem 4rem;
  
  `
const SectionOne = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* background-color: red; */
  /* height: 40%; */
  height: 100%;
  flex-direction: column ;
  gap: 2rem;
  /* width:100%; */
  width: 50%;
`
const SectionTwo = styled.div`
  display: flex;
  flex-direction: column ;
  /* background-color: blue; */
  gap: 2rem;
  width: 100%;
  height:100%;
`

const Expenses = styled.div`
  color:white;
`
const ExpenseInfo = styled.div`
      display: flex;
      width: inherit;
      justify-content: space-between;
      margin-bottom: 1rem;
      color: black;
`
const ExpensesContainer = styled.div`
      max-width: 350px;
      /* overflow-x: scroll; */
      display: flex;
      justify-content: center;
      gap: 10px;
`
const ButtonBox = styled.div`
      /* max-width: 350px; */
      /* overflow-x: scroll; */
      display: flex;
      justify-content: center;
      gap: 10px;
`

const Expense = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.3rem;
        img {
          height: 4rem;
          max-height: 4rem;
          overflow: hidden;
          border-radius: 2rem;
          transition: 0.4s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }
        h6 {
          color: #f94144;
        }
`

const StyledButton = styled.button`
  background-color: var(--black);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;

        img {
 
          max-height: 3rem;
          overflow: hidden;
          border-radius: 2rem;
          transition: 0.4s ease-in-out;
          &:hover {
            transform: scale(1.1);
          }
        }

`;
const EndpointsComponent = () => {
  const { user } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  let history = useHistory();

  // if(user.user.role === 'user'){
  //   history.push('/')
  // }
  const openModal = () => {
    setShowModal(prev => !prev);
  };



  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => {
      setVisible(false);
      console.log('closed');
  };
    

  
  
  const [reloadEndpoints, setReloadEndpoints] = useState(false);
  const [endpoints, setEndpoints] = useState(null)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const getEndpoints = async () => {
      setLoading(true)
      try {
          const {data} = await axios.get('/api/endpoints',{   
            headers: {
            'Authorization': `Bearer ${user.token}` 
          }})
          console.log(data.endpoints)
 
          setEndpoints(data.endpoints)
      
          setLoading(false)
          setReloadEndpoints(false)
      } catch (error) {
        setLoading(false)
          //console.log(error)
          // setSessions({...values})
      }
  };

  getEndpoints();

  }, [reloadEndpoints]);


  const initalState = {
    endpointValue:'',
    description: '',
  };

  const [state, setState] = useState(initalState);
  const [error, setError] = useState('');


  const handleSubmit = async e => {
    e.preventDefault();
    //console.log('submitted!');
    //console.log(state);

    // for (let key in state) {
    //   if (state[key] === '') {
    //     setError(`You must provide the ${key}`)
    //     return
    //   }
    // }
    // setError('');
    try {
      const {data} = await axios.post("/api/add-endpoint", state, 
      {   
        headers: {
        'Authorization': `Bearer ${user.token}` 
      }});

      toast.dark('✔️ Endpoint created successfully!', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
        //console.log(data)
        // window.location.reload(false);
        setReloadEndpoints(true);

    } catch (err) {
      // console.log(err.response.data)
      toast.error(` ❌ ${err.response.data}`, {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      setError(true);
    }
    //console.log("Succeeded!!!")
  };

  const handleInput = e => {
    //console.log('aaaaaaaa')
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState(prev => ({ ...prev, [inputName]: value }));
  };


  return (
    <>
    {/* <Modal showModal={showModal} setShowModal={setShowModal} /> */}

{/*     
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <CreateUserForm setReloadUsers={setReloadUsers}/>
    </Modal> */}

    <Container>
      <Wrapper>
        <ExpenseInfo>
            <h4>Current Endpoints</h4>
            <ButtonBox>
            <Button auto shadow onClick={handler}>
            Add new Endpoint
    </Button>
            {/* <StyledButton onClick={openModal} >+</StyledButton> */}
            </ButtonBox>
        </ExpenseInfo>

        <Modal
            closeButton
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
        >
          <form onSubmit={handleSubmit}>

            <Modal.Header>
                <Text id="modal-title" size={18}>
                Add new Endpoint
                </Text>
            </Modal.Header>
            <Modal.Body>

                <Input
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    size="lg"
                    placeholder="Value"
                    name="endpointValue"
                    value={state.endpointValue}
                    onChange={handleInput}
      
                />
                <Input
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    size="lg"
                    name="description"
                    placeholder="Description"
                    value={state.description}
                    onChange={handleInput}
      
                />

            </Modal.Body>
            <Modal.Footer>
                {/* <Button auto flat color="error" onClick={closeHandler}>
                Close
                </Button> */}
                <Button auto onClick={closeHandler}>
                Add
                </Button>
            </Modal.Footer>
                        
          </form>
        </Modal>
        {loading ? <Loading size="xl"/> : <EndpointList endpoints={endpoints} setReloadEndpoints={setReloadEndpoints}/>}
      </Wrapper>
    </Container>
    </>
  )
}

const Endpoints = MotionHoc(EndpointsComponent);

export default Endpoints;
