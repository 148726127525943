import React, { useEffect, useState, useContext } from 'react';
import { CalendarToday, LocationSearching, MailOutline, PermIdentity, PhoneAndroid, Publish } from '@material-ui/icons';
import "./user.css"
import { Link, useLocation,useHistory } from 'react-router-dom';
import { Card, Grid, Col, Text,Row,Button, Divider,Input, Spacer, Loading, Progress,Container, Modal } from '@nextui-org/react';

import styled from 'styled-components';
import axios from "axios";
import { toast } from 'react-toastify';
import { FaBeer } from 'react-icons/fa'
import { Context } from '../../context/Context'
// import { Modal } from '../../components/ModalCreate/Modal';

const Select = styled.select`
  width: 200px;
//   max-width: 350px;
//   min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  // background-color: rgb(209, 207, 207);
  // background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`
const Form = styled.form`

`;
export default function User() {

  let history = useHistory();



  const [isUpdating, setIsUpdating] = useState(false);

    const [values, setValues] = useState({
        username: '',
        email: '',
        password: '',
        role: '',
        userData: '',
    });


    const {
        email, username, role, password, userData
    } = values;

    const { user } = useContext(Context);
    const location = useLocation();
    const id = location.pathname.split("/")[2];

    if(user.user.role === 'user'){
      history.push('/')
    }

    useEffect(() => {
        const getUser = async () => {
            try {
                const { data } = await axios.get('/api/user/find/' + id, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                })
                //console.log(data)
                setValues({
                    username: data.username,
                    email: data.email,
                    role: data.role,
                })
            } catch (error) {
                //console.log(error)
                setValues({ ...values })
            }
        };
        getUser()
    }, [id])




    const handleChange = name => e => {
        // //console.log(e.target.value);
        const value = name === 'photo' ? e.target.files[0] : e.target.value;
        let userFormData = new FormData();
        userFormData.set(name, value);
        setValues({ ...values, [name]: value, userData: userFormData, error: false, success: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            //console.log(values)
            const { data } = await axios.put(`/api/user/update/${id}`, values, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            //console.log()
            setValues({
                username: data.username,
                email: data.email,
                role: data.role,
            })
            toast.dark(' ✔️ Update successful!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (err) {
            //console.log(err)
            toast.dark(` ❌ ${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <>
          <Container justify="center" AlignContent="center" responsive>
            <Grid.Container justify="center" AlignContent="center" responsive>
              <Grid>
                <Card bordered shadow={false} hoverable css={{ w: "400px" }}>
                <Card.Header>
                  <Text  b>Update user info</Text>
                </Card.Header>
                <Divider/>
                <Form onSubmit={handleSubmit}>
                  <Card.Body css={{ py: '$10' }}>
                    <Text >
                    Username
                    </Text>
                    <Spacer y={1}/>
                    <Input
                      clearable
                      bordered
                      fullWidth
                      color="primary"
                      size="lg"
                      name="username"
                      placeholder="Username"
                      value={username}
                      onChange={handleChange('username')}
                    />
                    <Spacer y={1}/>
                    <Text >
                    Email
                    </Text>
                    <Spacer y={1}/>
                    <Input
                      clearable
                      bordered
                      fullWidth
                      color="primary"
                      size="lg"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleChange('email')}
                    />
                    <Spacer y={1}/>
                    <Text >
                    Password
                    </Text>
                    <Spacer y={1}/>
                    <Input
                        clearable
                        bordered
                        fullWidth
                        color="primary"
                        size="lg"
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={handleChange('password')}
                      />
                      <Spacer y={1}/>
                      <Text >
                    Role
                    </Text>
                    <Spacer y={1}/>
                      <Select  value={role} onChange={handleChange('role')}>
                        <option value="" hidden>
                        Select a role
                        </option>
                        <option value="user">Standard</option>
                        <option value="admin">Admin</option>


                    </Select>
          
                  </Card.Body>
                  <Divider/>
                  <Card.Footer>
                    <Row justify="center">
                      {isUpdating ? (
                          <Col justify="center">
                            <Loading type="points-opacity"/>
                          </Col>
                      ) :       
                      <Button auto  type="submit">
                      Update
                      </Button>
                      }
                    </Row>
                  </Card.Footer>
                </Form>
                </Card>
              </Grid>
            </Grid.Container>
          </Container>

            {/* <Container>
                <Wrapper>
                    <FormContainer onSubmit={handleSubmit}>
                        <Title>
                            User Update
                        </Title>
                        <Form>
                        <LeftForm>
                            <label htmlFor="name">Name</label>
                            <StyledInput value={username} onChange={handleChange('username')} />
                            <label htmlFor="name">Email</label>
                            <StyledInput value={email} onChange={handleChange('email')} />
                        </LeftForm>
                        <RightForm>
                            <label htmlFor="name">Password</label>
                            <StyledInput type="password" value={password} onChange={handleChange('password')} />
                            <label htmlFor="name">Role</label>
                            <Select  value={role} onChange={handleChange('role')}>
                                <option value="" hidden>
                                Select a role
                                </option>
                                <option value="user">Standard</option>
                                <option value="admin">Admin</option>


                            </Select>
                        </RightForm>
                        </Form>
                        <StyledButton type="submit" submit>Submit</StyledButton>
                    </FormContainer>
                </Wrapper>
            </Container> */}
        </>
    )
}