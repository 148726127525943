import React, { useEffect, useState, useContext } from 'react'
import { CalendarToday, LocationSearching, MailOutline, PermIdentity, PhoneAndroid, Publish } from '@material-ui/icons';
import "./me.css"
import { Card, Grid, Col, Text,Row,Button, Divider,Input, Spacer, Loading, Progress,Container, Modal } from '@nextui-org/react';
import { Link, useLocation,useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios from "axios";
import { toast } from 'react-toastify';
import { FaBeer } from 'react-icons/fa'
import { Context } from '../../context/Context'
// import { Modal } from '../../components/ModalCreate/Modal';

const Form = styled.form`

`;
const Select = styled.select`
  width: 200px;
//   max-width: 350px;
//   min-width: 250px;
  height: 40px;
  border: none;
  margin: 0.5rem 0;
  // background-color: rgb(209, 207, 207);
  // background-color: #f5f5f5;
  box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  transition: all 0.2s ease-in;
  &:hover {
    transform: translateY(-3px);
  }
`


export default function Me() {

  let history = useHistory();



    const [isUpdating, setIsUpdating] = useState(false);
    const [values, setValues] = useState({
        username: '',
        email: '',
        password: '',
        role: '',
        userData: '',
    });


    const {
        email, username, role, password, userData
    } = values;

    const { user } = useContext(Context);
    const location = useLocation();
    // const id = location.pathname.split("/")[2];

    // if(user.user.role === 'user'){
    //   history.push('/')
    // }
    console.log(user)

    useEffect(() => {
        const getUser = async () => {
          setIsUpdating(true)

            try {
                const { data } = await axios.get('/api/user/find/' + user.user._id, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                })
                //console.log(data)
                setValues({
                    username: data.username,
                    email: data.email,
                    role: data.role,
                })
                setIsUpdating(false)
            } catch (error) {
                //console.log(error)
                setIsUpdating(false)
                setValues({ ...values })
            }
        };
        getUser()
    }, [])




    const handleChange = name => e => {
        // //console.log(e.target.value);
        const value = name === 'photo' ? e.target.files[0] : e.target.value;
        let userFormData = new FormData();
        userFormData.set(name, value);
        setValues({ ...values, [name]: value, userData: userFormData, error: false, success: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUpdating(true)
        try {
            //console.log(values)
            const { data } = await axios.put(`/api/user/update/${user.user._id}`, values, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            //console.log()
            setValues({
                username: data.username,
                email: data.email,
                role: data.role,
            })
            toast.success('Update successful!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsUpdating(false)
        } catch (err) {
            //console.log(err)
            toast.success(`${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsUpdating(false)
        }
    }

    return (
      <Container justify="center" AlignContent="center" responsive>
        <Grid.Container justify="center" AlignContent="center" AlignItems="center" > 
          <Grid >  
            {user.user.role === 'user' ? (
          <Card bordered shadow={false} hoverable css={{ w: "400px" }}>
          <Card.Header>
            <Text  b>Update user info</Text>
          </Card.Header>
          <Divider/>
          <Form onSubmit={handleSubmit}>
            <Card.Body css={{ py: '$10' }}>
              <Text >
               Username
              </Text>
              <Spacer y={1}/>
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                name="username"
                placeholder="Username"
                value={username}
                onChange={handleChange('username')}
              />
              <Spacer y={1}/>
              <Text >
               Email
              </Text>
              <Spacer y={1}/>
              <Input
                clearable
                bordered
                fullWidth
                color="primary"
                size="lg"
                name="email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleChange('email')}
              />
              <Spacer y={1}/>
              <Text >
               Password
              </Text>
              <Spacer y={1}/>
              <Input
                  clearable
                  bordered
                  fullWidth
                  color="primary"
                  size="lg"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={handleChange('password')}
                />
            </Card.Body>
            <Divider/>
            <Card.Footer>
              <Row justify="center">
                {isUpdating ? (
                    <Col justify="center">
                      <Loading type="points-opacity"/>
                    </Col>
                ) :       
                <Button auto  type="submit">
                Update
                </Button>
                }
              </Row>
            </Card.Footer>
          </Form>
      </Card>
            ) : (
              <Card bordered shadow={false} hoverable css={{ w: "400px" }}>
              <Card.Header>
                <Text  b>Update user info</Text>
              </Card.Header>
              <Divider/>
              <Form onSubmit={handleSubmit}>
                <Card.Body css={{ py: '$10' }}>
                  <Text >
                   Username
                  </Text>
                  <Spacer y={1}/>
                  <Input
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    size="lg"
                    name="username"
                    placeholder="Username"
                    value={username}
                    onChange={handleChange('username')}
                  />
                  <Spacer y={1}/>
                  <Text >
                   Email
                  </Text>
                  <Spacer y={1}/>
                  <Input
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    size="lg"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleChange('email')}
                  />
                  <Spacer y={1}/>
                  <Text >
                   Password
                  </Text>
                  <Spacer y={1}/>
                  <Input
                      clearable
                      bordered
                      fullWidth
                      color="primary"
                      size="lg"
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={handleChange('password')}
                    />
                    <Spacer y={1}/>
                    <Text >
                   Role
                  </Text>
                  <Spacer y={1}/>
                    <Select  value={role} onChange={handleChange('role')}>
                      <option value="" hidden>
                      Select a role
                      </option>
                      <option value="user">Standard</option>
                      <option value="admin">Admin</option>


                  </Select>
        
                </Card.Body>
                <Divider/>
                <Card.Footer>
                  <Row justify="center">
                    {isUpdating ? (
                        <Col justify="center">
                          <Loading type="points-opacity"/>
                        </Col>
                    ) :       
                    <Button auto  type="submit">
                    Update
                    </Button>
                    }
                  </Row>
                </Card.Footer>
              </Form>
              </Card>
            )}
          </Grid>  
        </Grid.Container>
      </Container>
    )
}