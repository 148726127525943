import MotionHoc from "./MotionHoc";
import { useContext, useRef,useState } from "react";
import { Context } from "../context/Context";
import { Input,Spacer,Card,Button,Loading   } from '@nextui-org/react';
import styled from "styled-components";
import {  toast } from 'react-toastify';
import { Link, Redirect ,useHistory} from "react-router-dom";
import axios from "axios";

const Form = styled.form`
  display: flex;
  flex-direction: column;

  label{
    color:white;
  }
  input{
    color:white;
  }

`

const HomeComponent = () => {

  const userRef = useRef();
  const passwordRef = useRef();
  let history = useHistory();

  const { user, dispatch, isFetching } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    // console.log('hola')
    dispatch({ type: "LOGIN_START" });
    try {

        const res = await axios.post("/api/signin", {
        email: userRef.current.value,
        password: passwordRef.current.value,
      });
      setIsLoading(false)
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      toast.dark('✔️ Success!', {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } catch (error) {
      setIsLoading(false)
      //console.log(error.response)
      toast.dark(` ❌ ${error.response.data.error}`, {
        position: "top-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Card.Image
              objectFit="contain"
              src={'https://res.cloudinary.com/erracode/image/upload/v1616454090/logo_q386lr.png'}
              width='100%'
              height={140}
  
            />
        <Spacer y={2}/>
        <Input type="email" labelPlaceholder="Email" clearable bordered color="primary"  ref={userRef}/>
        <Spacer y={2}/>
        <Input.Password type="password" labelPlaceholder="Password" clearable bordered color="primary"  ref={passwordRef} />
        <Spacer y={2}/>
        {isLoading ? <Loading/> : <Button type="submit" >Sign in</Button> }
     
      </Form>
    </>
  )
};

const Home = MotionHoc(HomeComponent);

export default Home;
