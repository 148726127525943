import React, { useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, ReplayOutlined, GetApp } from '@material-ui/icons';
import { Avatar, Modal, Button,Card } from '@nextui-org/react';
// import { userRows } from "../../dummydata"
import { Link } from 'react-router-dom'
import "./scrapeResults.css"

// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";
import { Context } from '../../context/Context';
import axios from 'axios';
import { toast } from 'react-toastify'
import styled from 'styled-components'



export default function ScrapeResults(props) {
  console.log(props)
  const { scrapeResults } = props

  const { user } = useContext(Context);
  // const [data, setData] = useState(userRows)
  const [visible, setVisible] = useState(false);
  const [urlImage, setUrlImage] = useState(false);

  const openImage = (url) => {
    console.log(url)
    setUrlImage(url)
    setVisible(true);
    // setShowImage(url)
  }

  const closeHandler = () => {
    setVisible(false);
    console.log('closed');
};

  console.log(urlImage)


  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'username', headerName: 'Name', width: 120 },
    { field: 'domain', headerName: 'Domain', width: 150 },
    { field: 'phone', headerName: 'Phone', width: 120 },
    {
      field: 'email',
      headerName: 'Email',
      //   type: 'number',
      width: 120,
    },
    {
      field: 'avatar', headerName: 'Image', width: 120, renderCell: (params) => {
        return (
           <div className="userListUser">
           {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
           <Avatar size="lg" src={params.row.avatar} onClick={() => openImage(params.row.avatar)} color="primary" bordered />
            </div> 
        )
      }
    },

  ];



    
 

  const StyledForm = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
`;




  return (
    <div className="height: 800px !important;">
              {/* <StyledModal showModal={showImage} setShowModal={setShowImage} >
            <Image src={urlImage} alt={'algun titulo'} />
        </StyledModal> */}
    <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
    >

        <Modal.Body>
          <Card cover css={{ w: '100%' }}>
            <Card.Body>
              <Card.Image
                src={urlImage}
                // src='https://nextui.org/images/card-example-5.jpeg'
                height={400}
                width="100%"
                alt="Card example background"
              />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
            <Button auto flat color="error" onClick={closeHandler}>
            Close
            </Button>
        </Modal.Footer>
    </Modal>
      {scrapeResults ? <DataGrid rows={scrapeResults} getRowId={(row) => row.id} disableSelectionOnClick columns={columns} pageSize={10}  /> : <> No data </>}

    </div>
  )
}