import React, { useState, useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { DeleteOutline, ReplayOutlined, GetApp } from '@material-ui/icons';

// import { userRows } from "../../dummydata"
import { Link } from 'react-router-dom'
import "./userList.css"

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Context } from '../../context/Context';
import axios from 'axios';
import { toast } from 'react-toastify'
import styled from 'styled-components'



export default function Userlist(props) {
  const { users, setReloadUsers } = props

  const { user } = useContext(Context);
  // const [data, setData] = useState(userRows)

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const { data } = await axios.delete(`/api/users/${id}`,
                {
                  headers: {
                    'Authorization': `Bearer ${user.token}`
                  }
                });

              toast.dark(' ✔️ User deleted successfully!', {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              //console.log(data)
              setReloadUsers(true)

            } catch (err) {
              //console.log(err)
              toast.success(` ❌ ${err}`, {
                position: "top-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            }
          }
        },
        {
          label: 'No',
          onClick: () => console.log('not deleted')
        }
      ]
    });
  };


  const columns = [
    // { field: '_id', headerName: 'ID', width: 250 },
    { field: 'username', headerName: 'Name', width: 200 },
    { field: 'role', headerName: 'Role', width: 120 },
    {
      field: 'email',
      headerName: 'Email',
      //   type: 'number',
      width: 200,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/user/" + params.row._id}>
              <button className="userListEdit">Edit</button>
            </Link>
            {/* <button className="userListEdit" onClick={() => {
              setShowModal(prev => !prev)
              setShowCredentials(params.row)
            }} >Credentials</button> */}
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>

        )
      }
    },

  ];

  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(prev => !prev);
  };

  const StyledForm = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  /* background-color: #fff; */
  border-radius: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  input{
    background-color: #d1cfcf;
  }
  >button{
    margin-top: 20px;
  }
`;




  return (
    <div className="userList">

      {users ? <DataGrid rows={users} getRowId={(row) => row._id} disableSelectionOnClick columns={columns} pageSize={10}  /> : <> No data </>}

    </div>
  )
}